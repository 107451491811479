import React from 'react'
import { Link } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import { motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import ImageCarousel from '/src/components/carousel/ImageCarousel'

import './home_image_carousel.scss'

const HomeImageCarouselNavigation = () => {
  return <></>
}

const HomeImageCarouselItem = ({ image, content, link }) => {
  return (
    <Row className="carousel-item-container">
      <Col xs={12} md={7}>
        <div className="d-flex justify-content-center align-items-center carousel-item-content">
          <motion.div className="content">
            <div dangerouslySetInnerHTML={{__html: content}} />
            {link && (
              <Link className="button" to={link.href}>
                {link.title}
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            )}
          </motion.div>
        </div>
      </Col>
      <Col xs={12} md={5}>
        <motion.div className="image" style={{
          backgroundImage: `url(${image})`
        }} />
      </Col>
    </Row>
  )
}

const HomeImageCarouselPagination = ({ images, activeImageIndex, skipToImage }) => {
  const isActive = (index) => {
    return activeImageIndex === index
  }

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Row className="carousel-pagination">
        {images.map((image, index) => {
          if (isActive(index)) {
            return (
              <Col key={index} sm={4} className="p-0">
                <button className="active" key={index}>
                  {image.title}
                </button>
              </Col>
            )
          }

          return (
            <Col key={index} sm={4} className="p-0">
              <button onClick={() => skipToImage(index)}>
                {image.title}
              </button>
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export const HomeImageCarousel = ({ images }) => {
  if (!images.length) {
    return <></>
  }

  return (
    <Col sm={12} className="home-carousel-container">
      <ImageCarousel 
        images={images}
        ImageView={HomeImageCarouselItem}
        NavigationView={HomeImageCarouselNavigation}
        PaginationView={HomeImageCarouselPagination}
      />
    </Col>
  )
}

export default HomeImageCarousel
