import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout/home'
import { ProductRangeBlock } from '/src/components/commerce'
import { NewsListBlock } from '/src/components/news'

const IndexPage = () => {
  return (
    <Layout>
      <Row className="d-flex justify-content-center content-row">
        <Col md={10}>
          <Row className="d-flex align-items-center justify-content-center">
            <ProductRangeBlock />
          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mb-4">
        <Col md={10}>
          <Row className="d-flex align-items-center justify-content-center">
            <NewsListBlock />
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default IndexPage

export const Head = () => (
  <>
    <title>Home | Orrcon Steel</title>
    <meta name="description" content="Orrcon Steel is a leading Australian distributor and manufacturer of steel, tube and pipe. Our extensive product range covers RHS, SHS & CHS structural tubular steel, hot rolled structural steel and a variety of fencing, roofing and building accessories." />
    <meta name="og:description" property="og:description" content="Your One-Stop Shop for Effortless Ordering of Premium Steel Products. Buy online or call us today. At Orrcon Steel, we'll see it through" />
    <meta property="og:image" content="https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/og_orrcon.png" />
  </>
)
